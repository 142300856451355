import { useEffect } from 'react';

import { ToolGroup } from '../constants/landing';
import { getToolGroups } from '../constants/landing';

export default function useExpandStack(
  expandedGroupId: string | null,
  setLayout: (layout: ToolGroup[]) => void,
  setIsTransitioning: (isTransitioning: boolean) => void,
) {
  useEffect(() => {
    setIsTransitioning(true);

    const newLayout = [...getToolGroups()].map((group, index) => ({
      ...group,
      position: index % 2,
      row: Math.floor(index / 2),
    }));

    if (expandedGroupId) {
      const expandedIndex = newLayout.findIndex((g) => g.id === expandedGroupId);
      if (expandedIndex !== -1) {
        const expandedGroup = newLayout[expandedIndex];
        const expandedRow = expandedGroup.row || 0;

        // Always move expanded group to the start of its row
        newLayout[expandedIndex] = { ...expandedGroup, position: 0 };

        newLayout.forEach((group, i) => {
          if (i !== expandedIndex && group.row === expandedRow) {
            newLayout[i] = {
              ...group,
              position: 1,
            };
          }
        });
      }
    }

    newLayout.sort((a, b) => {
      if ((a.row || 0) === (b.row || 0)) {
        return (a.position || 0) - (b.position || 0);
      }
      return (a.row || 0) - (b.row || 0);
    });

    setLayout(newLayout);

    const timeoutId = setTimeout(() => {
      setIsTransitioning(false);
    }, 50);

    return () => clearTimeout(timeoutId);
  }, [expandedGroupId]);
}
