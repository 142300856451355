import { CalendarIcon,
  FlagIcon,
  GlobeAltIcon,
  BoltIcon,
  PencilSquareIcon,
  SparklesIcon,
  ArrowsRightLeftIcon,
  PhotoIcon,
  PresentationChartBarIcon,
  BookOpenIcon,
  ClipboardDocumentListIcon,
  DocumentTextIcon,
  LightBulbIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { Features } from "@lessonPlanner/constants";

import { mainRoutes } from './routes';

export const landingTypedWords = [
  'Lesson Plan',
  'Activity',
  'Assessment',
  'Objective',
];

interface ToolConfig {
  icon: typeof BookOpenIcon;
  gradient: string;
  description: string;
}

// Define group types
export interface ToolGroup {
  id: keyof typeof TOOL_GROUPS;
  name: string;
  tools: {
    title: string;
    description: string;
    icon: React.ElementType;
    gradient: string;
    badge: string | null;
    link: string;
  }[];
  position?: number;
  row?: number;
}

export const TOOL_GROUPS = {
  planning: {
    name: "Lesson Planning",
    features: [
      Features.LESSON_PLAN,
      Features.UNIT_PLAN,
      Features.DLL,
      Features.OBJECTIVES,
      Features.SUBJECT_EXTENSIONS,
      Features.CATCH_UP_FRIDAYS,
      Features.TOPIC_EXPLORER,
    ],
  },
  visuals: {
    name: "Visuals",
    features: [
      Features.PPT_MAKER,
      Features.PHOTO_BOOTH,
    ],
  },
  assessment: {
    name: "Assessment",
    features: [
      Features.SUMMATIVE_TEST,
      Features.QUIZMAKER,
    ],
  },
  engagement: {
    name: "Student Engagement",
    features: [
      Features.ENERGIZER,
      Features.ELIF,
      Features.ACTIVITIES,
      Features.ANALOGY,
    ],
  },

} as const;

const DEFAULT_TOOL_CONFIG: ToolConfig = {
  icon: BookOpenIcon,
  gradient: 'linear-gradient(to right, rgb(59, 130, 246), rgb(34, 211, 238))',
  description: 'Tool to enhance your teaching experience.',
};

export const toolsConfig: Partial<Record<Features, ToolConfig>> = {
  [Features.UNIT_PLAN]: {
    icon: BookOpenIcon,
    gradient: 'linear-gradient(to right, rgb(33, 115, 196), rgb(0, 195, 225))',
    description: 'Create detailed unit plans with clear learning objectives and activities.',
  },
  [Features.LESSON_PLAN]: {
    icon: BookOpenIcon,
    gradient: 'linear-gradient(to right, rgb(59, 130, 246), rgb(34, 211, 238))',
    description: 'Craft comprehensive lesson plans in minutes, tailored to your grade level, subject, and objectives.',
  },
  [Features.DLL]: {
    icon: DocumentTextIcon,
    gradient: 'linear-gradient(to right, rgb(245, 158, 11), rgb(249, 115, 22))',
    description: 'Create daily lesson logs with clear learning objectives and activities.',
  },
  [Features.SUMMATIVE_TEST]: {
    icon: ClipboardDocumentListIcon,
    gradient: 'linear-gradient(to right, rgb(168, 85, 247), rgb(167, 139, 250))',
    description: 'Evaluate student understanding and progress through summative assessments.',
  },
  [Features.TOPIC_EXPLORER]: {
    icon: MagnifyingGlassIcon,
    gradient: 'linear-gradient(to right, rgb(99, 102, 241), rgb(59, 130, 246))',
    description: 'Discover related concepts and resources to enrich your lessons.',
  },
  [Features.PPT_MAKER]: {
    icon: PresentationChartBarIcon,
    gradient: 'linear-gradient(to right, rgb(16, 185, 129), rgb(45, 212, 191))',
    description: 'Create engaging presentations in minutes with our PPT maker.',
  },
  [Features.PHOTO_BOOTH]: {
    icon: PhotoIcon,
    gradient: 'linear-gradient(to right, rgb(99, 102, 241), rgb(59, 130, 246))',
    description: 'Generate engaging images for your lesson plans, unit plans, presentations, and more.',
  },
  [Features.ENERGIZER]: {
    icon: SparklesIcon,
    gradient: 'linear-gradient(to right, rgb(234, 179, 8), rgb(251, 191, 36))',
    description: 'Quick activities to boost classroom energy and engagement.',
  },
  [Features.ELIF]: {
    icon: LightBulbIcon,
    gradient: 'linear-gradient(to right, rgb(245, 158, 11), rgb(249, 115, 22))',
    description: "Break down complex concepts into easy-to-understand explanations.",
  },
  [Features.ACTIVITIES]: {
    icon: BoltIcon,
    gradient: 'linear-gradient(to right, rgb(217, 70, 239), rgb(168, 85, 247))',
    description: 'Ready-to-use classroom activities aligned with your curriculum.',
  },
  [Features.QUIZMAKER]: {
    icon: PencilSquareIcon,
    gradient: 'linear-gradient(to right, rgb(132, 204, 22), rgb(34, 197, 94))',
    description: 'Quick assessments to gauge understanding and retention.',
  },
  [Features.CATCH_UP_FRIDAYS]: {
    icon: CalendarIcon,
    gradient: 'linear-gradient(to right, rgb(236, 72, 153), rgb(244, 63, 94))',
    description: "Address learning gaps and reinforce concepts in dedicated sessions that take just moments to plan.",
  },
  [Features.SUBJECT_EXTENSIONS]: {
    icon: GlobeAltIcon,
    gradient: 'linear-gradient(to right, rgb(20, 184, 166), rgb(16, 185, 129))',
    description: 'Connect concepts across subjects for holistic education.',
  },
  [Features.OBJECTIVES]: {
    icon: FlagIcon,
    gradient: 'linear-gradient(to right, rgb(244, 63, 94), rgb(248, 113, 113))',
    description: "Break down learning objectives into a week's worth of classes, ensuring alignment with cognitive, affective, and psychomotor learning domains.",
  },
  [Features.ANALOGY]: {
    icon: ArrowsRightLeftIcon,
    gradient: 'linear-gradient(to right, rgb(139, 92, 246), rgb(168, 85, 247))',
    description: 'Create powerful comparisons to make abstract concepts concrete.',
  },
};

// Helper function to generate grouped tools
export function getToolGroups(): ToolGroup[] {
  const NEW_LABEL = 'New';
  const HOT_LABEL = 'Hot';

  return Object.entries(TOOL_GROUPS).map(([id, group]) => ({
    id: id as keyof typeof TOOL_GROUPS,
    name: group.name,
    tools: group.features.map(feature => {
      const config = toolsConfig[feature] || DEFAULT_TOOL_CONFIG;
      const routeConfig = mainRoutes.find(route => route.featureKey === feature);

      return {
        title: routeConfig?.label || feature,
        description: config.description,
        icon: config.icon,
        gradient: config.gradient,
        badge: feature === Features.PPT_MAKER ||
               feature === Features.PHOTO_BOOTH ||
               feature === Features.TOPIC_EXPLORER
          ? NEW_LABEL
          : [Features.LESSON_PLAN, Features.DLL, Features.SUMMATIVE_TEST].includes(feature)
          ? HOT_LABEL
          : null,
        link: routeConfig?.link || '',
      };
    }),
  }));
}
