import { trackEvent } from 'apps/web/utils/tracking';

import { EVENTS } from 'apps/web/utils/tracking';
import clsx from 'clsx';

interface AttributionProps {
  className?: string;
  justify?: 'center' | 'start';
}

export default function Attribution({
  className,
  justify = 'center',
}: AttributionProps) {
  function clicked(props) {
    trackEvent(EVENTS.SOCIAL_MEDIA_LINK_CLICKED, props);
  }
  return (
    <p
      className={clsx(
        'text-xs/relaxed mx-auto flex items-center text-slate-600',
        justify === 'center' ? 'justify-center' : 'justify-start',
        className,
      )}
    >
      Crafted with
      <svg
        className="mx-1 h-4 w-4 text-red-300"
        fill="currentColor"
        stroke="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
        ></path>
      </svg>
      by{' '}
      <a
        className="ml-1 underline"
        href="https://www.symph.co/"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => clicked({ name: 'Symph' })}
      >
        Symph
      </a>
    </p>
  );
}
