import { useState } from 'react';

import { ToolGroup } from 'apps/web/constants/landing';

import CardBadge, { BadgeType } from '../molecules/CardBadge';

export default function ToolsCollapsedStack({
  group,
  toggleExpand,
}: {
  group: ToolGroup;
  toggleExpand: (id: string) => void;
}) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative h-80 cursor-pointer transition-all duration-200 hover:scale-105"
      onClick={() => toggleExpand(group.id)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {group.tools.slice(0, 3).map((tool, index) => {
        // Calculate position and rotation for stacked effect
        const stackRotation = index % 2 === 0 ? -3 + index : 3 - index;
        const stackTranslateX =
          index % 2 === 0 ? -5 + index * 2 : 5 - index * 2;
        const stackTranslateY = index * 8;
        const stackZIndex = 10 - index;
        const stackScale = 1 - index * 0.025;

        return (
          <div
            key={`stack-${group.id}-${tool.title}`}
            className="absolute h-full w-full transition-all duration-200"
            style={{
              transform: `rotate(${
                isHovered ? 0 : stackRotation
              }deg) translateX(${stackTranslateX}px) translateY(${stackTranslateY}px) scale(${stackScale})`,
              zIndex: stackZIndex,
              opacity: 1 - index * 0.1,
            }}
          >
            <div className="h-full overflow-hidden rounded-lg shadow-lg">
              <div className="h-full" style={{ background: tool.gradient }}>
                <div className="relative h-full p-6 text-white">
                  {tool.badge && (
                    <div className="absolute right-2 top-2">
                      <CardBadge type={tool.badge as BadgeType} />
                    </div>
                  )}
                  {/* <tool.icon className="h-12 w-12 mb-4" /> */}
                  <h3 className="mb-2 text-4xl font-bold">{tool.title}</h3>
                  <p className="line-clamp-2 text-xl text-white/90">
                    {tool.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {/* Tool count indicator */}
      {group.tools.length > 3 && (
        <div className="absolute bottom-4 right-4 rounded-full bg-white/20 px-3 py-1 text-xs font-medium text-white backdrop-blur-sm">
          +{group.tools.length - 3} more
        </div>
      )}
    </div>
  );
}
