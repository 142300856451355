export enum BadgeType {
  HOT = 'Hot',
  NEW = 'New',
}

interface CardBadgeProps {
  type: BadgeType;
}

const badgeConfig = {
  [BadgeType.HOT]: {
    icon: '🔥',
    className: 'text-orange-500 animate-pulse',
  },
  [BadgeType.NEW]: {
    icon: '✨',
    className: 'text-emerald-600',
  },
};

export default function CardBadge({ type }: CardBadgeProps) {
  const config = badgeConfig[type];

  return (
    <span
      className={`inline-flex items-center rounded-full bg-white px-3 py-1 text-xs font-medium shadow-lg ${config.className}`}
    >
      <span className="mr-1">{config.icon}</span> {type}
    </span>
  );
}
