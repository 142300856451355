import Link from 'next/link';
import { Features } from '@lessonPlanner/constants';

type RouteItem = {
  link: string;
  label: string;
  featureKey: Features;
  isNewFeature?: boolean;
};

interface ToolsListProps {
  routes: RouteItem[];
}

const ToolsList = ({ routes }: ToolsListProps) => {
  return (
    <>
      <h3 className="font-bold">Tools</h3>
      <ul className="mt-2">
        {routes.map((route, index) => (
          <li key={index} className="mt-5">
            <Link href={route.link}>
              {route.label}
              {route.isNewFeature && (
                <span className="ml-2 rounded bg-blue-500 px-1.5 py-0.5 text-xs text-white">
                  New
                </span>
              )}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};

export default ToolsList;
