'use client';

import { useState, useRef } from 'react';

import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import { ToolGroup } from 'apps/web/constants/landing';
import { getToolGroups } from 'apps/web/constants/landing';
import useExpandStack from 'apps/web/hooks/useExpandStack';
import clsx from 'clsx';

import ToolsCollapsedStack from './ToolsCollapsedStack';
import ToolsExpandedStack from './ToolsExpandedStack';

export interface Tool {
  title: string;
  description: string;
  icon: React.ElementType;
  gradient: string;
  badge: string | null;
}

const TRANSITION_STYLES = {
  property: 'transform, opacity, grid-column-start, grid-column-end',
  duration: '500ms',
  timing: 'cubic-bezier(0.4, 0, 0.2, 1)',
} as const;

function getColumnSpan(isExpanded: boolean, expandedCols: number): string {
  if (!isExpanded) return '';
  return expandedCols === 2 ? 'sm:col-span-2' : 'sm:col-span-2 md:col-span-3';
}

function calculateOrder(row = 0, position = 0, isExpanded = false): number {
  if (isExpanded) return 0;
  return row * 2 + position + 1;
}

export default function PositionTransitionTools() {
  const [expandedGroupId, setExpandedGroupId] = useState<string | null>(null);
  const [layout, setLayout] = useState<ToolGroup[]>(getToolGroups());
  const [isTransitioning, setIsTransitioning] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const expandedGroupRef = useRef<HTMLDivElement>(null);

  useExpandStack(expandedGroupId, setLayout, setIsTransitioning);

  const toggleExpand = (groupId: string) => {
    if (isTransitioning) return;
    if (expandedGroupId === groupId) {
      setExpandedGroupId(null);
    } else {
      setExpandedGroupId(groupId);
      setTimeout(() => {
        expandedGroupRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 100);
    }
  };

  const renderHeader = () => {
    return (
      <div className="mb-12 flex flex-col items-center justify-center text-center">
        <p className="pt-5 text-xl font-semibold text-[#387DFD] md:text-2xl">
          Empower Your Teaching
        </p>
        <p className="pt-3 text-4xl font-extrabold md:text-6xl">
          Explore our Tools
        </p>
        <p className="mt-2 w-full pt-3 pb-3 text-lg text-gray-600 sm:w-2/3 md:text-2xl">
          Discover easy-to-use tools that make lesson planning a breeze and help
          you create engaging learning experiences for your students.
        </p>
      </div>
    );
  };

  const renderGroupHeader = (group: ToolGroup, isExpanded: boolean) => {
    return (
      <div className="mb-3 flex items-center justify-between">
        <h3
          className={clsx(
            'mb-2 text-4xl font-semibold text-gray-800 transition-all duration-300',
            isExpanded && 'text-xl',
          )}
        >
          {group.name}
        </h3>
        <button
          aria-label={isExpanded ? 'Collapse' : 'Expand'}
          className="rounded-full p-1 transition-colors hover:bg-gray-200"
          disabled={isTransitioning}
          onClick={() => toggleExpand(group.id)}
        >
          {isExpanded ? (
            <ArrowUpIcon className="h-4 w-4" />
          ) : (
            <ArrowDownIcon className="h-4 w-4" />
          )}
        </button>
      </div>
    );
  };

  return (
    <div
      ref={containerRef}
      className="container mb-8 w-full px-4 md:px-6 xl:px-24"
    >
      {renderHeader()}
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
        {layout.map((group) => {
          const isExpanded = expandedGroupId === group.id;
          const expandedCols = 2;

          return (
            <div
              key={group.id}
              ref={isExpanded ? expandedGroupRef : null}
              className={clsx(
                'mt-12 transition-all duration-500 ease-in-out',
                getColumnSpan(isExpanded, expandedCols),
              )}
              style={{
                order: calculateOrder(group.row, group.position, isExpanded),
                transitionProperty: TRANSITION_STYLES.property,
                transitionDuration: TRANSITION_STYLES.duration,
                transitionTimingFunction: TRANSITION_STYLES.timing,
              }}
            >
              {renderGroupHeader(group, isExpanded)}

              {/* Collapsed stack view */}
              {!isExpanded && (
                <ToolsCollapsedStack
                  group={group}
                  toggleExpand={toggleExpand}
                />
              )}

              {/* Expanded view */}
              {isExpanded && <ToolsExpandedStack group={group} />}
            </div>
          );
        })}
      </div>
    </div>
  );
}
