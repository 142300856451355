import { ToolGroup } from 'apps/web/constants/landing';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import { Button } from '../Button';
import CardBadge, { BadgeType } from '../molecules/CardBadge';

export default function ToolsExpandedStack({ group }: { group: ToolGroup }) {
  const router = useRouter();
  return (
    <div
      className={clsx(
        'animate-in fade-in-0 slide-in-from-top-4 m-4 grid h-auto gap-4 duration-500',
        'grid-cols-1 sm:grid-cols-2',
      )}
    >
      {group.tools.map((tool, index) => (
        <div
          key={`tool-${group.id}-${tool.title}`}
          className="group relative h-80 overflow-hidden shadow-lg transition-all duration-300 hover:-translate-y-1 hover:shadow-xl"
          style={{
            transitionDelay: `${index * 50}ms`,
            borderRadius: '0.5rem',
          }}
        >
          <div
            className="absolute inset-0 bg-gradient-to-br opacity-90 transition-all group-hover:opacity-100"
            style={{ background: tool.gradient, borderRadius: '0.5rem' }}
          />
          <div className="relative p-6 text-white">
            {tool.badge && (
              <div className="absolute right-2 top-2">
                <CardBadge type={tool.badge as BadgeType} />
              </div>
            )}
            {/* <tool.icon className="h-12 w-12 mb-4" /> */}
            <h3 className="mb-2 text-4xl font-bold">{tool.title}</h3>
            <p className="text-xl text-white/90">{tool.description}</p>
            <div className="mt-4 flex items-center">
              <Button
                className="inline-flex translate-y-2 items-center justify-center rounded-md bg-white/10 px-4 py-2 text-sm font-medium text-white/70 opacity-0 backdrop-blur-sm transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-white/40 group-hover:translate-y-0 group-hover:bg-white/20 group-hover:text-white group-hover:opacity-100"
                onClick={() => router.push(tool.link)}
              >
                Explore
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
