export interface BlogPost {
  id: number;
  date: string;
  authorImage: string;
  author: string;
  title: string;
  headline: string;
  postImage: string;
}

export const blogPosts: BlogPost[] = [
  {
    id: 1,
    date: 'July 22, 2024',
    authorImage: '',
    author: 'Lesson Planner',
    title: 'Singapore School AI Presentation',
    headline:
      'Lesson Planner team presents AI for Education at Singapore School Cebu',
    postImage: '/images/posts/singapore-school.jpg',
  },
  {
    id: 2,
    date: 'June 21, 2024',
    authorImage: '',
    author: 'Lesson Planner',
    title: 'DICT SGF Demo Day',
    headline: "Lesson Planner Joins DICT's Startup Grant Fund Pioneer Cohort!",
    postImage: '/images/posts/dict.jpg',
  },
  {
    id: 3,
    date: '',
    authorImage: '',
    author: 'Lesson Planner',
    title: 'Edutech Asia 2023',
    headline:
      'Lesson Planner PH Joins the Global Edtech Startups over at EduTech Asia 2023',
    postImage: '/images/posts/edu-tech-asia.jpg',
  },
  {
    id: 4,
    date: '',
    authorImage: '',
    author: 'Lesson Planner',
    title: "DepEd presentation '24",
    headline:
      'LP Continues Growing Partnership with DepEd Region 7 Through a Regional Feedbacking Session to Improve the Solution',
    postImage: '/images/posts/DepEd-presentation.JPG',
  },
  {
    id: 5,
    date: '',
    authorImage: '',
    author: 'Lesson Planner',
    title: 'Geeks on a beach 2023',
    headline:
      "JP Madulid, Co-Founder and CEO, Shares Lesson Planner PH's One-Year Journey in Solving Teachers' Problems through AI at Geeks on A Beach 2023",
    postImage: '/images/posts/geeks-on-a-beach-23.jpg',
  },
  {
    id: 6,
    date: 'July 11, 2024',
    authorImage: '',
    author: 'Lesson Planner',
    title: 'Partnership with Cerebro',
    headline:
      'Lesson Planner Partners with Cerebro to Elevate Classroom Learning',
    postImage: '/images/posts/cerebro.jpg',
  },
  {
    id: 7,
    date: 'August 7, 2024',
    authorImage: '',
    author: 'Lesson Planner',
    title: 'MOU Signing with DepEd QA R7',
    headline:
      'DepEd Region 7 & Lesson Planner Join Forces for Education Innovation',
    postImage: '/images/posts/mou-signing.jpg',
  },
];
